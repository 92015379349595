
import React from "react";
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg";
import MainLayout from "../../layouts/MainLayout";
import "./how-was-made.scss";

const HowWasMade = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("how-was-made:metaTitle")}>
      <>
        <HeroImg customClass="how-was-made-hero" title={t("how-was-made:heroTitle")} backgroundImage="bg/how-was-made-hero.jpg"/>

        <div className="how-was-made-section-two">

          <div className="how-was-made-section-two-box">
            <h3>{t("how-was-made:sectionOneTitle")}</h3>
            <p className="box-two">{t("how-was-made:sectionOneTextOne")}</p>
            <p>{t("how-was-made:sectionOneTextTwo")}</p>
          </div>
        </div>

        <HeroImg customClass="how-was-made-middle" backgroundImage="bg/how-was-made-middle.jpg">
          {
            <h3 className="how-was-made-middle-text">{t("how-was-made:sectionTwoTitle")}</h3>
          }
        </HeroImg>
        <div className="how-was-made-section-two">

          <div className="how-was-made-section-two-box">
            <p className="box-two">{t("how-was-made:sectionThreeTextOne")}</p>
            <p>{t("how-was-made:sectionThreeTextTwo")}</p>
          </div>

        </div>

        <HeroImg customClass="how-was-made-bottom" backgroundImage="bg/how-was-made-bottom.jpg">
          {
            <h3 className="how-was-made-bottom-text">{t("how-was-made:sectionFourTitle")}</h3>
          }
        </HeroImg>
      </>
    </MainLayout>
  )
}

export default HowWasMade;